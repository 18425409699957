<template>
  <v-app>
    <v-main style="width: 100%">
      <router-view v-if="isMobile" />
      <router-view v-else />
    </v-main>
  </v-app>
</template>

<script>
export default {
  components: {},
  name: "App",
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
};
</script>
