import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(() => {
    window.location.reload();
    window.scrollTo(0, 0);
  });
};

Vue.use(VueRouter);

const validProductTypes = [
  "CROWN",
  "RAMINATE",
  "BRACES",
  "DENTURE",
  "TMPTOOTH",
  "IMPLANT",
];
const updateIsMobile = () => {
  const isMobile =
    /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ||
    /Mobi/i.test(window.navigator.userAgent) ||
    "ontouchstart" in document.documentElement ||
    window.innerWidth < 600;
  store.commit("setIsMobile", isMobile);
};

const routes = [
  {
    path: "/",
    name: "home",
    component: () => {
      // store에서 isMobile 값 가져오기
      updateIsMobile(); // 동적으로 isMobile 값을 반영
      return store.state.isMobile
        ? import("../views/mobile/MobileHomeView.vue")
        : import("../views/HomeView.vue");
    },
  },
  {
    path: "/introduce",
    name: "introduce",
    component: () => {
      // store에서 isMobile 값 가져오기
      updateIsMobile(); // 동적으로 isMobile 값을 반영
      return store.state.isMobile
        ? import("../views/mobile/MobileIntroduceView.vue")
        : import("../views/IntroduceView.vue");
    },
  },
  {
    path: "/devices",
    name: "devices",
    component: () => {
      // store에서 isMobile 값 가져오기
      updateIsMobile(); // 동적으로 isMobile 값을 반영
      return store.state.isMobile
        ? import("../views/mobile/MobileDevicesView.vue")
        : import("../views/DevicesView.vue");
    },
  },

  {
    path: "/contact",
    name: "contact",
    component: () => {
      // store에서 isMobile 값 가져오기
      updateIsMobile(); // 동적으로 isMobile 값을 반영
      return store.state.isMobile
        ? import("../views/mobile/MobileContactView.vue")
        : import("../views/ContactView.vue");
    },
  },
  {
    path: "/customtable",
    name: "customtable",
    component: () => {
      // store에서 isMobile 값 가져오기
      updateIsMobile(); // 동적으로 isMobile 값을 반영
      return store.state.isMobile
        ? import("../views/mobile/MobileCustomTableView.vue")
        : import("../views/CustomtableView.vue");
    },
  },
  {
    path: "/freesample",
    name: "freesample",
    component: () => {
      // store에서 isMobile 값 가져오기
      updateIsMobile(); // 동적으로 isMobile 값을 반영
      return store.state.isMobile
        ? import("../views/mobile/MobileFreeSampleView.vue")
        : import("../views/FreesampleView.vue");
    },
  },
  {
    path: "/products/:type",
    name: "products",
    component: () => {
      // store에서 isMobile 값 가져오기
      updateIsMobile(); // 동적으로 isMobile 값을 반영
      return store.state.isMobile
        ? import("../views/mobile/MobileProductsView.vue")
        : import("../views/ProductsView.vue");
    },

    beforeEnter: (to, from, next) => {
      if (validProductTypes.includes(to.params.type.toUpperCase())) {
        next();
      } else {
        console.error("올바르지 않은 페이지입니다");
        next({ name: "404" });
      }
    },
  },
  {
    path: "/solutions",
    name: "solutions",

    component: () => {
      // store에서 isMobile 값 가져오기
      updateIsMobile(); // 동적으로 isMobile 값을 반영
      return store.state.isMobile
        ? import("../views/mobile/MobileSolutionsView.vue")
        : import("../views/SolutionsView.vue");
    },
  },
  {
    path: "/404",
    name: "404",
    component: () => {
      // store에서 isMobile 값 가져오기
      updateIsMobile(); // 동적으로 isMobile 값을 반영
      return store.state.isMobile
        ? import("../views/mobile/MobileNotFoundView.vue")
        : import("../views/NotFoundView.vue");
    },
  },

  {
    path: "*",
    name: "not-found",
    beforeEnter: (to, from, next) => {
      console.error("올바르지 않은 페이지입니다");
      next({ name: "404" });
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});
router.beforeEach((to, from, next) => {
  updateIsMobile(); // 첫 진입 시 isMobile 값 설정
  next();
});

export default router;
